import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import ReactPlayer from 'react-player'
import { useMount } from 'react-use'

import { Layout } from '../components'
import { container, padding, colours, type } from '../styles/global'
import { media } from '../styles/utils'
import { getVideoURL } from '../utils'

import char01 from '../assets/images/character-white-02.png'

// Todo: Not sure why I have to use pageContext props over data?

const Projects = ({ pageContext, ...props }) => {
    const { data } = pageContext
    const [videoLoaded, setVideoLoaded] = useState(false)

    // Video

    const renderVideo = () => {
        if (!pageContext.acf.video) return

        return (
            <Video
                controls
                playsinline
                onReady={() => setVideoLoaded(true)}
                url={getVideoURL(pageContext.acf.video)}
                width={'100%'}
                height={'100%'}
            />
        )
    }

    // Caption

    const renderCredit = () => {
        if (!pageContext.acf.short_description) return

        return (
            <Credit
                dangerouslySetInnerHTML={{
                    __html: pageContext.acf.short_description,
                }}
            />
        )
    }

    return (
        <Layout meta={data && data.seo} hideLogo={true}>
            <Container>
                <Meta>
                    <ProjectInfo>
                        {pageContext.acf.brand && (
                            <Brand
                                dangerouslySetInnerHTML={{
                                    __html: pageContext.acf.brand,
                                }}
                            />
                        )}

                        {pageContext.title && (
                            <Title
                                dangerouslySetInnerHTML={{
                                    __html: pageContext.title,
                                }}
                            />
                        )}

                        {pageContext.acf.type && (
                            <Type
                                dangerouslySetInnerHTML={{
                                    __html: pageContext.acf.type,
                                }}
                            />
                        )}
                    </ProjectInfo>

                    <Close to="/">Close</Close>
                </Meta>

                {!videoLoaded && (
                    <Loading
                        initial={{ scale: 0.8, x: '-50%', y: '-50%' }}
                        animate={{ scale: 1, x: '-50%', y: '-50%' }}
                        transition={{ easing: 'easeInOut', yoyo: Infinity }}
                        src={char01}
                    />
                )}

                <VideoWrapper ready={videoLoaded}>{renderVideo()}</VideoWrapper>

                {renderCredit()}
            </Container>
        </Layout>
    )
}

// Shared

const Brand = styled.h2``
const Title = styled.h1``
const Type = styled.h2``
const Video = styled(ReactPlayer)``

// Container

const Container = styled.div`
    ${container}
    ${padding}
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background: ${colours.black};
    color: white;
    padding: 50px 60px;
    position: relative;

    ${media.phone`
		padding: 40px 30px;
	`}
`

// Meta

const Meta = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 50;
    position: relative;
`

const ProjectInfo = styled.div`
	z-index: 40;

	${Brand}, 
	${Title}, 
	${Type} {
		font-size: 24px;
		color: white;

		${media.phone`
			font-size: 18px;
		`}
	}
`

// Close

const Close = styled(Link)`
    box-sizing: border-box;
    font-size: 24px;
    color: white;

    ${media.phone`
		font-size: 18px;
	`}
`

// Video

const VideoWrapper = styled.div`
    opacity: 0;
    transition: 1s ease;

    ${(props) => {
        if (props.ready)
            return css`
                opacity: 1;
            `
    }};

    ${Video} {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 0;
        padding: 16vh 60px;
        box-sizing: border-box;

        ${media.phone`
			padding: 16vh 0;
		`}
    }
`

// Credit

const Credit = styled.h2`
    p {
        font-size: 24px;
        margin: 0;

        ${media.phone`
			font-size: 18px;
		`}
    }
`

// Loading

const Loading = styled(motion.img)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    max-width: 300px;
`

export default Projects
